import { addSelection, Key, subtractSelection } from "./Selections";
import { PlotVariable, Questions, Responses, ResultResponseWithCache, Textvar } from "./Graphs";
import { REQUIRED_ITEM_DOMAINS } from "./Constants";
import { PetraProtocolSubscriptionTextvars } from "./Results";
import { TextvarValue } from "../../../grapqhl";

// - Children of a flag are flags with a depends_on to this flag.
// - impact of a flags is the questions it shows or hides.
// - There are 3 types of flags:
//   1. flags with children and no impact
//      * these are folders, the domains, subdomains, subsubdomains, ...
//      * we don't select them in the flagSelection but calculate them as selected before creating the subscription.
//   2. flags with no children and with impact
//      * these are the normal flags that can be selected/deselected in the interface.
//   3. flags with no children and no impact.
//      * these are fake flags that represent questions that are hidden by default and are show by
//        answers to other questions.
//      * they are disabled checkboxes in the interface, selected based on their parents.
//
// The defaultInInterface attribute of a flag is used only to determine which
// flags to select from the item domains listed for a subgoal or complaint.
// E.g., if I select the "Angst" goal, and I see in the COMPLAINTS constant
// in Constants.jsx that that for the semi-random protocol this sets the
// SEMI_ANGST item domain, then what indeed will happen is that all leaf
// nodes that are descendant of this domain will be preselected by the system.

// -------------- Type Definitions --------------

// A wrapper object for handling flags. There only lives a single
// FlagsInterface object in the application, which is contructed
// in the Petra component and then passed down where needed.
// Depending on the value of the `design` property (in MeasurementSchedule),
// `flags` property is the array of flags from the fixed questionnaire,
// the semi random questionnaire, or the one time per day questionnaire.
export interface FlagInterface {
  flagsSelection: Selections<Key>;
  flags: FlagObject[];
  textvars: TextvarObject[];
  textvarsSettings: TextvarsSettings;
  questions: Questions;
}

export interface TextvarsSettings {
  textvarsValues: TextvarsValues;
  setTextvarsValues: (newValues: TextvarsValues) => void;
}

// An object where the key is a textvar key, and the value is the contents of that textvar.
export interface TextvarsValues {
  [name: string]: string;
}

// Function for converting the textvars we get from a protocol subscription to the TextvarsValues type.
export const convertToTextvarsValues = (textvars: PetraProtocolSubscriptionTextvars): TextvarsValues => {
  const result: TextvarsValues = {};
  for (const textvar of textvars) {
    if (textvar.value !== null) {
      result[textvar.key] = textvar.value;
    }
  }
  return result;
};

// A FlabObject is the representation of a flag in Petra.
// The properties `key` and `id` are assumed to be identical,
// but we use `key` everywhere.
// This definition is straight from Quby, except that we
// added a new property, `impactSignature`. The impact signature
// of a flag is defined as the the stringified concatenation of the
// `showsQuestions` and `hidesQuestions` arrays. Flags that have
// the same impact signature have the same "impact," i.e., they have
// the same "effect." We use this in PETRA to sync the
// checked/not checked state for flags with identical impact signatures
// (only for flags that have impact).
export interface FlagObject {
  key: Key;
  id: Key;
  description: string;
  showsQuestions: Key[];
  hidesQuestions: Key[];
  dependsOn: Key[];
  defaultInInterface: boolean;
  internal: boolean;
  impactSignature: string;
  topDomain?: string;
}

// A TextvarObject is the representation of a textvar in Petra.
// The properties `key` and `id` are assumed to be identical,
// but we use `key` everywhere.
export interface TextvarObject {
  key: Key;
  id: Key;
  description: string;
  default: string;
  dependsOnFlag: Key | null;
  currentValue: string;
}

// -------------- Functions --------------

// Returns true if the given flag has children (= direct descendants), i.e., if there exist
// other flags whose `dependsOn` array include the given key.
export const hasChildren = (flag: Key, flags: FlagObject[]) => {
  return flags.filter((curFlag) => curFlag.dependsOn.includes(flag)).length > 0;
};

// Returns the subdomains of a given flag.
// Subdomains are the children of a flag that have children themselves.
export const subDomains = (flag: Key, flags: FlagObject[]) => {
  return flags.filter((curFlag) => curFlag.dependsOn.includes(flag) && hasChildren(curFlag.key, flags));
};

// Returns the children of a flag that do not have children, i.e., the non-subdomains.
export const directChildrenWithoutChildren = (flag: Key, flags: FlagObject[]) => {
  return flags.filter((curFlag) => curFlag.dependsOn.includes(flag) && !hasChildren(curFlag.key, flags));
};

// Returns all of the "leaf nodes" (= flags without children) that fall under a flag
// (i.e., they are either leaf nodes of the flag itself or of any of the flag's descendants).
export const descendantsWithoutChildren = (flag: Key, flags: FlagObject[]) => {
  let result = directChildrenWithoutChildren(flag, flags);
  for (const subDomain of subDomains(flag, flags)) {
    result = result.concat(descendantsWithoutChildren(subDomain.key, flags));
  }
  return result;
};

// Returns true if the description of either the given flag or of any of its descendants
// matches the given query.
// Precondition: the query is in lowercase
export const matchesQuery = (query: string, flag: Key, flags: FlagObject[]) => {
  return currentflagMatchesQuery(query, flag, flags) || childrenMatchQuery(query, flag, flags);
};

// Returns true if the description of any of the descendants of the given flag
// matches the given query.
// Precondition: the query is in lowercase
export const childrenMatchQuery = (query: string, flag: Key, flags: FlagObject[]) => {
  const children = flags.filter((curFlag) => curFlag.dependsOn.includes(flag));
  for (const child of children) {
    if (matchesQuery(query, child.key, flags)) return true;
  }
  return false;
};

// Returns true if the description of the given flag matches the given query.
// Precondition: the query is in lowercase
export const currentflagMatchesQuery = (query: string, flag: Key, flags: FlagObject[]) => {
  const myFlag = flags.filter((curFlag) => curFlag.key === flag)[0];
  if (myFlag.description.toLowerCase().includes(query)) {
    return true;
  }

  const curFlagLabels = flagLabels(myFlag);
  for (const label of curFlagLabels) {
    if (label.includes(query)) return true;
  }
  return false;
};

// Returns an array of the keys of all ancestors of the given flag.
// An ancestor is either a parent flag (a flag whose key appears in the given flag's `dependsOn`
// attribute), or is a parent flag of any of those parent flags, and so on.
// This function supports the fact that flags can have multiple ancestors (i.e., that
// `dependsOn` is an array).
// Precondition: no circular references.
export const ancestors = (flag: Key, flags: FlagObject[]) => {
  const myFlag = flags.filter((curFlag) => curFlag.key === flag)[0];
  if (myFlag.dependsOn.length === 0) return [];

  const allAncestors = flags.filter((curFlag) => myFlag.dependsOn.includes(curFlag.key));
  let result: string[] = [];
  for (const ancestor of allAncestors) {
    result = [...result, ancestor.key, ...ancestors(ancestor.key, flags)];
  }
  return result;
};

// Returns for a given list of flag keys, all children without children
// with the `defaultInInterface` attribute set to true.
export const defaultFlagsInInterfaceForDomains = (keys: Key[], flags: FlagObject[]) => {
  const resultingKeys: Key[] = [];
  for (const key of keys) {
    for (const flag of descendantsWithoutChildren(key, flags)) {
      if (flag.defaultInInterface) {
        resultingKeys.push(flag.key);
      }
    }
  }
  return Array.from(new Set(resultingKeys));
};

// For a given flag key, toggles its inclusion in the given Selections<Key> and also
// performs the same operation (either selecting or deselecting)
// on flags with the same impact signature. Returns the new list of selected items.
// An impact signature is the "impact" that changing a flag has. It is based on the
// `showsQuestions` and `hidesQuestions` arrays. If two flags have the same value
// for these two properties, they have the same impact signature, and otherwise
// their impact signature differs.
// The impact signature is used to detect "duplicate flags," i.e., flags that have
// the same effect (they show and hide the same sets of questions).
// We use this to sync the status of flags with the same impact signature, so that
// for a given impact signature, all flags with that impact signature are either
// selected or deselected (and not that some of them are selected while others aren't).
// We only sync the status of flags that have impact, i.e., who have a non empty
// array for the `hidesQuestions` or `showsQuestions` attributes (or for both).
export const toggleFlag = (key: Key, selection: Selections<Key>, flags: FlagObject[]) => {
  if (selection.isSelected(key)) {
    // Given a flag key, deselect all flags with the same impact signature
    // (or if the flag has no impact, only the given flag) in the given Selections<Key>,
    // and return the new list of selected items.
    // For an explanation of impact signatures, see the explanation of the `toggleFlag` function above.
    return subtractSelection(selection)(flagsLike(key, flags));
  }
  // Given a flag key, select all flags with the same impact signature
  // (or if the flag has no impact, only the given flag) in the given Selections<Key>,
  // and return the new list of selected items.
  return addSelection(selection)(flagsLike(key, flags));
};

// Given a flag key return the keys of the flags with the same impact signature
// (or if the flag has no impact, only the given flag key).
// Has an optional argument to remove flags that don't have impact.
const flagsLike = (key: Key, flags: FlagObject[], removeNoImpactFlags: boolean = false): string[] => {
  const flag = flags.filter((curFlag) => curFlag.key === key)[0];
  // In case we change the questionnaire, and the given flags from an old design
  // that we want to repeat are no longer valid.
  if (!flag) return [];

  const flagImpact = flagHasImpact(flag);
  if (removeNoImpactFlags && !flagImpact) return [];

  const similarFlags = flagImpact
    ? flags.filter((curFlag) => flag.impactSignature === curFlag.impactSignature)
    : [flag];
  return similarFlags.map((flag) => flag.key);
};

// Same as above but can be called with an array of keys
export const flagsLikeMultiple = (keys: Key[], flags: FlagObject[], removeNoImpactFlags: boolean = false): string[] => {
  let result: string[] = [];
  for (const key of keys) {
    result = [...result, ...flagsLike(key, flags, removeNoImpactFlags)];
  }
  return Array.from(new Set(result));
};

// A flag has impact if it has a non empty array for the
// `hidesQuestions` or `showsQuestions` attributes (or for both).
export const flagHasImpact = (flag: FlagObject) => {
  return flag.hidesQuestions.length > 0 || flag.showsQuestions.length > 0;
};

// For a given Selections<Key>, return the number of distinct impact signatures of selected flags.
// Flags without impact are counted if their direct parent flag is in the includeAllAncestors full
// set of selected flags.
// For an explanation of impact signatures, see the explanation of the `toggleFlag` function above.
export const uniqueSelectedFlagCount = (selection: Selections<Key>, flags: FlagObject[]) => {
  const impactSignatures = new Set<string>();
  let hiddenFlagCount = 0;
  // allSelected is the set of selected flags with impact + all their ancestors
  const allSelected = includeAllAncestors(selection, flags);
  for (const flag of flags) {
    if (!determineSelected(flag, selection, flags, allSelected)) continue;
    if (flagHasImpact(flag)) {
      // Perform possible deduplication on flags with identical
      // impact, i.e., those that show the same question(s).
      // For flags without impact, we assume that they do not occur
      // in the questionnaire multiple times.
      impactSignatures.add(flag.impactSignature);
    } else if (!hasChildren(flag.key, flags)) {
      // !hasChildren so that we only count leafnodes, because this
      // is for a counting of questions, not domains.
      hiddenFlagCount += 1;
    }
  }
  return impactSignatures.size + hiddenFlagCount;
};

// Returns the total number of questions for the entire questionnaire.
// Flags without impact signature are always counted.
// For flags with impact signature we only count the number of distinct impact signatures.
export const totalQuestionCount = (flags: FlagObject[]) => {
  const impactSignatures = new Set<string>();
  let hiddenFlagCount = 0;
  for (const flag of flags) {
    if (flagHasImpact(flag)) {
      // Perform possible deduplication on flags with identical
      // impact, i.e., those that show the same question(s).
      // For flags without impact, we assume that they do not occur
      // in the questionnaire multiple times.
      impactSignatures.add(flag.impactSignature);
    } else if (!hasChildren(flag.key, flags)) {
      // !hasChildren so that we only count leafnodes, because this
      // is for a counting of questions, not domains.
      hiddenFlagCount += 1;
    }
  }
  return impactSignatures.size + hiddenFlagCount;
};

// Returns the number of flags for a (sub)domain.
export const descendantQuestionCount = (flag: Key, flags: FlagObject[]) => {
  return totalQuestionCount(descendantsWithoutChildren(flag, flags));
};

// Selects all questions of a (sub)domain.
export const selectAllQuestions = (flag: Key, flags: FlagObject[], selection: Selections<Key>) => {
  let toBeSelected: Key[] = [];
  for (const curFlag of descendantsWithoutChildren(flag, flags)) {
    if (!flagHasImpact(curFlag)) continue;

    toBeSelected = [...toBeSelected, ...flagsLike(curFlag.key, flags)];
  }
  addSelection(selection)(toBeSelected);
};

// Unselects all questions of a (sub)domain.
export const unselectAllQuestions = (flag: Key, flags: FlagObject[], selection: Selections<Key>) => {
  let toBeUnselected: Key[] = [];
  for (const curFlag of descendantsWithoutChildren(flag, flags)) {
    if (!flagHasImpact(curFlag)) continue;

    toBeUnselected = [...toBeUnselected, ...flagsLike(curFlag.key, flags)];
  }
  // Don't unselect required flags
  toBeUnselected = toBeUnselected.filter((curFlag) => !isRequiredFlag(curFlag, flags));
  subtractSelection(selection)(toBeUnselected);
};

// Look up is the current flag is required. Note that there is no conflicting keys here since the
// flag keys include the name of their questionnaire.
export const isRequiredDomain = (flag: Key) => {
  for (const design of Object.keys(REQUIRED_ITEM_DOMAINS)) {
    if (REQUIRED_ITEM_DOMAINS[design].includes(flag)) {
      return true;
    }
  }
  return false;
};

// Determine if a flag is required
export const isRequiredFlag = (flag: Key, flags: FlagObject[]) => {
  const curFlag = flags.filter((cFlag) => cFlag.key === flag)[0];
  if (!curFlag.defaultInInterface) return false;

  const parents = parentFlags(curFlag, flags);
  return parents.some((parentFlag) => isRequiredDomain(parentFlag.key));
};

// Returns the parent flag objects of a flag
const parentFlags = (flag: FlagObject, flags: FlagObject[]) => {
  let result: FlagObject[] = [];
  for (const pFlag of flag.dependsOn) {
    const parentFlag = flags.filter((cFlag) => cFlag.key === pFlag)[0];
    // Parentflag can also be 'last_measurement_of_day' or 'first_measurement_of_day',
    // which are not in the list of flags.
    if (parentFlag !== undefined) result.push(parentFlag);
  }
  return result;
};

// Returns the selected items and all their ancestors. The result does not contain
// duplicate values.
export const includeAllAncestors = (selection: Selections<Key>, flags: FlagObject[]) => {
  const result = new Set<Key>();
  for (const key of selection.selected) {
    result.add(key);
    for (const ancestorKey of ancestors(key, flags)) {
      result.add(ancestorKey);
    }
  }
  return Array.from(result);
};

// For normal flags (i.e., those with impact), this simply returns whether or not the flag is selected.
// For a flag without impact, we return true if its immediate parent was included in the set of selected
// flags and all their ancestors. Because if it was, then that means that another sibling flag of this
// flag that does have impact was selected (assuming that "domain" flags have no impact themselves, or
// even if they did, they are not toggleable/selectable in the interface), hence they were selected by
// virtue of a sibling flag to the currentFlag, in which case we decide to count this flag as selected.
// The underlying assumption is that flags without impact are initially hidden in a questionnaire, and
// are shown when a certain option of another (initially visible) question in this category was toggled.
// And we decide to include them in the total number of flags selected since it is meant to indicate the
// maximum number of flags that someone can fill out given the current flags configuration.
export const determineSelected = (
  currentFlag: FlagObject,
  flagSelection: Selections<Key>,
  flags: FlagObject[],
  allSelected = includeAllAncestors(flagSelection, flags)
) => {
  if (flagHasImpact(currentFlag)) {
    return flagSelection.isSelected(currentFlag.key);
  }
  for (const flagKey of currentFlag.dependsOn) {
    // Return true if one of the parents matches (because typically flags only have one "parent,"
    // and if there is more than one, it is an internal flag set by RoQua (such as the beginning_of_day
    // or end_of_day flags)
    if (allSelected.includes(flagKey)) {
      return true;
    }
  }
  return false;
};

// Convert the Graphql `questionnaire.flags` result into typed flag objects to use in the
// rest of the application.
// We have to construct a array of typed objects because TypeScript does not
// allow us to interpret the whole array as a certain array of typed objects in one go.
// The impact signature is the "impact" or "effect" that toggling a flag has. If two
// flags have the same set of `showsQuestions and `hidesQuestions` properties, they
// should have the same impact signature.
export const typedFlags = (allFlags): FlagObject[] => {
  return allFlags
    .filter((flag) => !flag.internal)
    .map((flag) => {
      return {
        key: flag.key,
        id: flag.id,
        description: flag.description || "", // Ensure that PETRA flags always have a description
        showsQuestions: flag.showsQuestions, // (even if it is sometimes an empty string)
        hidesQuestions: flag.hidesQuestions,
        dependsOn: flag.dependsOn || [], // depends_on should always be an array for PETRA flags.
        defaultInInterface: flag.defaultInInterface || false,
        internal: flag.internal,
        impactSignature: JSON.stringify([[...flag.hidesQuestions].sort(), [...flag.showsQuestions].sort()]),
      };
    });
};

// Convert the Graphql `questionnaire.textvars` result into typed textvar objects to use in the
// rest of the application.
// We have to construct a array of typed objects because TypeScript does not
// allow us to interpret the whole array as a certain array of typed objects in one go.
export const typedTextvars = (allTextvars): TextvarObject[] => {
  return allTextvars.map((textvar) => {
    return {
      id: textvar.id,
      key: textvar.key,
      description: textvar.description || "",
      default: textvar.default || "",
      dependsOnFlag: textvar.dependsOnFlag || null,
      currentValue: textvar.default || "",
    };
  });
};

// Add the top domain directly in the object for each flag.
export const addTopDomains = (flags: FlagObject[]) => {
  for (const flag of flags) {
    let curFlag = flag;
    // if this is a top domain flag we don't set the property
    while (curFlag.dependsOn.length > 0) {
      // Use the first index in the depends on array
      curFlag = flags.filter((cFlag) => cFlag.key === curFlag.dependsOn[0])[0];
    }
    flag.topDomain = curFlag.description.replace(/\s?\(verplicht\)/g, "");
  }
};

// Return a set of keys for which we have at least one non-null answer.
export const keysWithAnswers = (responses: Responses) => {
  const rKeysWithAnswers = new Set<string>();
  for (const response of responses) {
    for (const value of response.values) {
      // Don't try to plot answers that aren't numbers
      if (value.value !== null && value.value.match(/^-?[0-9]+(\.[0-9]+)?$/)) {
        rKeysWithAnswers.add(value.key);
      }
    }
  }
  return rKeysWithAnswers;
};

// Does not actually remove all HTML code. This is just enough to
// remove <br> tags and such that may be present in question titles.
// Replaces " (...)" by ""
export const cleanupString = (str: string) => {
  return str
    .replace(/(<([^>]+)>)/gi, "")
    .replace(/( \(([^)]+)\))/gi, "")
    .replace(/&hellip;/gi, "...");
};

// Return a mapping of keys to arrays of category names (which are top domains).
const categoriesForKeys = (flags: FlagObject[]) => {
  const result = {};
  for (const flag of flags) {
    if (!flag.topDomain) continue;

    for (const key of flag.showsQuestions) {
      if (!result[key]) {
        result[key] = [];
      }
      if (!result[key].includes(flag.topDomain)) {
        result[key].push(flag.topDomain);
      }
    }
  }
  return result;
};

// Determine the variables to show in the graphs. This is determined based on if we have at least one response
// with a non-null value for the key of the variable.
export const categorizePlottableAnswers = (
  flags: FlagObject[],
  questions: Questions,
  responses: Responses
): PlotVariable[] => {
  const keysWithValues = Array.from(keysWithAnswers(responses));
  const keysCategories = categoriesForKeys(flags);
  const result: PlotVariable[] = [];
  for (const key of keysWithValues) {
    // Only plot floats.
    const descriptionLine = questions.filter((question) => question.type === "FLOAT" && question.key === key)[0];
    if (!descriptionLine) continue;

    let keyCategories = keysCategories[key];
    if (!keyCategories) keyCategories = ["Verdiepende vragen"];

    const shortDescription = cleanupString(descriptionLine.contextFreeTitle);
    if (!shortDescription) continue;

    const longDescription = cleanupString(descriptionLine.title || "");
    if (!longDescription) continue;

    for (const category of keyCategories) {
      result.push({
        key: key,
        shortDescription: shortDescription,
        longDescription: longDescription,
        initiallySelected: key === "v_1",
        category: category,
      });
    }
  }
  return result;
};

export const substituteTextvars = (
  variables: PlotVariable[],
  textvars: Textvar[],
  questionnaireKey: string
): PlotVariable[] => {
  const prefix = `${questionnaireKey}_`;
  const newTextvars = textvars
    .filter((textvar) => textvar.key.startsWith(prefix) && textvar.value !== null)
    .map((textvar) => {
      const unprefixedKey = textvar.key.substring(prefix.length);
      const searchStr = `{{${unprefixedKey}}}`;

      return { ...textvar, searchStr };
    });

  return variables.map((variable) => {
    // The ?? "" below is to make typescript know that the value is never null.
    const longDescription = newTextvars.reduce(
      (str, { searchStr, value }) => str.replaceAll(searchStr, value ?? ""),
      variable.longDescription
    );
    // Override the existing short description to be a shortened version of the longDescription.
    const shortDescription = longDescription
      .replace(/Op dit moment/, "Nu")
      .replace(/Sinds het vorige meetmoment/, "Sinds vorige meting");

    return { ...variable, longDescription, shortDescription };
  });
};

// Return the textvars rendered by a flag. These are semantically the textvars that appear in a question
// that is shown by the flag. These are syntactically the textvars that have the depends_on_flag property
// set to the key of the given currentFlag.
export const textVarsForFlag = (currentFlag: FlagObject, flags: FlagInterface): TextvarObject[] => {
  return flags.textvars.filter((textvar) => textvar.dependsOnFlag === currentFlag.key);
};

// Render labels for flags
interface LabelMapping {
  label: string;
  flag: Key;
}

const FIRST_MEASUREMENT_OF_DAY = "first_measurement_of_day";
const LAST_MEASUREMENT_OF_DAY = "last_measurement_of_day";

const FLAG_LABELS: LabelMapping[] = [
  { label: "ochtend", flag: FIRST_MEASUREMENT_OF_DAY },
  { label: "avond", flag: LAST_MEASUREMENT_OF_DAY },
];

export const flagLabels = (currentFlag: FlagObject): string[] => {
  const labels: string[] = [];
  FLAG_LABELS.forEach((labelMapping: LabelMapping) => {
    if (currentFlag.dependsOn.includes(labelMapping.flag)) {
      labels.push(labelMapping.label);
    }
  });
  return labels;
};

// A function that returns true if the set of selected flags contains both at least one
// "morning" flag and at least one "evening" flag, and false otherwise.
export const selectedBothMorningAndEveningQuestions = (flags: FlagInterface): boolean => {
  const result = new Set<Key>();
  for (const flag of flags.flagsSelection.selected) {
    const selectedFlag = flags.flags.filter((curFlag) => curFlag.key === flag)[0];
    for (const flagKey of selectedFlag.dependsOn) {
      result.add(flagKey);
    }
  }
  return result.has(FIRST_MEASUREMENT_OF_DAY) && result.has(LAST_MEASUREMENT_OF_DAY);
};

// A function that returns true if the set of selected flags contains no questions
// with the "morning" label, and false otherwise.
export const noMorningQuestionsSelected = (flags: FlagInterface): boolean => {
  for (const flag of flags.flagsSelection.selected) {
    const selectedFlag = flags.flags.filter((curFlag) => curFlag.key === flag)[0];
    if (selectedFlag.dependsOn.find((flagKey) => flagKey === FIRST_MEASUREMENT_OF_DAY)) return false;
  }
  return true;
};

// Returns true if the key belongs to a flag that depends on the first or last measurement of the day
// and false otherwise.
export const isOneTimePerDayQuestion = (key: Key, responsesWithCache: ResultResponseWithCache[]): boolean => {
  // Only treat the first questionnaire for now.
  if (responsesWithCache.length === 0) return false;

  const flags = responsesWithCache[0].questionnaire.flags;
  return !!flags.find(
    (flag) =>
      flag.dependsOn &&
      (flag.dependsOn.includes(FIRST_MEASUREMENT_OF_DAY) || flag.dependsOn.includes(LAST_MEASUREMENT_OF_DAY)) &&
      flag.showsQuestions.includes(key)
  );
};
