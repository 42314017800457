import React from "react";
import { FlagInterface, includeAllAncestors } from "../Flags";
import { useQubyFrontendV2Query } from "../../../../grapqhl";
import { Loader } from "../../../ui/loader";
import { Previewer } from "../../../Previewer";
import { IFlags, IQuestionnaire } from "@roqua/quby-frontend";
import { IValues } from "@roqua/quby-frontend/dist/core/response";

interface Props {
  flags: FlagInterface;
  questionnaireKey: string;
  handleSave: () => void;
}

// Previews the given questionnaire. GraphQL is smart so we only retrieve the questionnaire
// once at the start even if we change some flags and then preview the questionnaire again.
const PreviewQuestionnaire: React.FunctionComponent<Props> = (props: Props) => {
  const results = useQubyFrontendV2Query({
    variables: {
      questionnaireKey: props.questionnaireKey,
    },
  });

  if (results.loading) {
    return <Loader />;
  }

  if (!results?.data?.currentOrganization?.questionnaire?.qubyFrontendV2) {
    return <div>{JSON.stringify(results.error)}</div>;
  }

  const qubyFrontendV2Json = results.data.currentOrganization.questionnaire.qubyFrontendV2 as IQuestionnaire;

  const flags: IFlags = {};
  includeAllAncestors(props.flags.flagsSelection, props.flags.flags).forEach((key) => (flags[key] = true));

  const textvars = props.flags.textvarsSettings.textvarsValues;

  return (
    <Previewer questionnaireJson={qubyFrontendV2Json} flags={flags} textvars={textvars} handleSave={props.handleSave} />
  );
};

export default PreviewQuestionnaire;
