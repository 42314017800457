import React from "react";
import { IProtocol, IMeasurement } from "../../models";
import classNames from "classnames";
import { Translate } from "components/Translate";
import { Icon } from "components/ui/icon";

interface Props {
  protocol: IProtocol;
}

const measurementClassName = (measurement) => classNames("measurement-name", { active: measurement.active, inactive: !measurement.active });

const Measurement = ({ measurement }: { measurement: IMeasurement }) => {
  return (
    <li className={measurementClassName(measurement)}>
      <a href={`/admin/protocols/${measurement.protocol_id}/measurements/${measurement.id}/edit`}>{measurement.name}</a>
      {measurement.only_for_automation && <span style={{marginLeft: "0.25rem"}} className="badge">{I18n.t("activerecord.attributes.measurement.only_for_automation")}</span>}
      {!measurement.active && (
        <span className="label">
          <Translate content="admin_area.measurements.deleted_label" />
        </span>
      )}
    </li>
  );
};

const DeleteButton: React.FunctionComponent<{ protocol: IProtocol }> = ({ protocol }) => {
  const href = `/admin/protocols/${protocol.id}`;
  return (
    <a href={href} className="btn btn-danger" data-method="delete">
      <Icon name="trash" color="white" />
    </a>
  );
};

const RestoreButton: React.FunctionComponent<{ protocol: IProtocol }> = ({ protocol }) => {
  const href = `/admin/protocols/${protocol.id}/restore`;
  return (
    <a href={href} className="btn" data-method="patch">
      <Translate content="restore" />
    </a>
  );
};

export const Row: React.FunctionComponent<Props> = ({ protocol }) => {
  const className = classNames("sortable-row", { active: protocol.active, inactive: !protocol.active });

  return (
    <tr className={className}>
      <td>
        <i className="fa fa-arrows-v" />
      </td>
      <td>
        <a href={`/admin/protocols/${protocol.id}/edit`}>
          {protocol.name}
        </a>

        {!protocol.active && (
          <span className="label">
            <Translate content="admin_area.protocols.deleted_label" />
          </span>
        )}
      </td>

      <td>
        <ul className="no-bullets">
          {protocol.measurements.map((measurement) => (
            <Measurement key={measurement.id} measurement={measurement} />
          ))}
        </ul>
      </td>

      <td>{protocol.teams.length > 0 && protocol.teams.map((team) => team.name).join(", ")}</td>

      <td>
        <ul className="no-bullets">
          {protocol.auto_protocols.map((autoProtocol) => (
            <li key={autoProtocol.id}><a href={`/admin/auto_protocols/${autoProtocol.id}/edit`}>{autoProtocol.name}</a></li>
          ))}
        </ul>
      </td>

      <td>{protocol.active ? <DeleteButton protocol={protocol} /> : <RestoreButton protocol={protocol} />}</td>
    </tr>
  );
};
